<template>
  <div>
    <el-dialog ref='elForm' v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle'
               width='80%'>
      <LogList ref='refLogList' :info-id='infoId' />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApeTable from '@/components/ApeTable'
import LogList from '@/pages/lb/components/LogList'

export default {
  name: 'LbScheduleLog',
  components: { LogList, ApeTable },
  props: {
    infoId: {
      type: [Number, String]
    }
  },
  watch: {
    infoId: {
      immediate: true,
      handler(val) {
        this.searchCondition.info_id = val

      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    dialogTitle() {
      return `直播排期日志`
    }
  },
  data() {
    return {
      loadingStatus: false,
      searchCondition: { op_type: null, info_id: null },
      dataList: [],
      total: 0,
      pageData: { page_size: 10, current_page: 1 },
      logForm: {}
    }
  },
  methods: {
    onOpen() {
      this.logForm = {}
      this.$nextTick(() => {
        this.$refs['refLogList'].getList()
      })

    },
    onClose() {
      this.dataList = []
    },
    close() {
      this.$emit('update:visible', false)
    },

    async getList() {
      this.dataList = []
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pageData)
      let { list, pages } = await this.$api.getLbScheduleLog(searchCondition)
      this.$nextTick(() => {
        this.dataList = list || []
      })
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 1
      this.total = pages.total
    },
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.info_id) {
        condition.info_id = this.searchCondition.info_id
      }

      if (this.searchCondition.op_type) {
        condition.op_type = this.searchCondition.op_type
      }

      return condition
    },
    async showLogDetail(row) {
      let logForm = await this.$api.getLbScheduleLogDetail(row.id)
      if (logForm)
        this.logForm = logForm
    }
  }
}
</script>

<style scoped>

</style>
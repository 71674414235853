<template>
  <div>
    <el-row :gutter='15'>
      <el-col :lg='10' :xs='24' :sm='24' :md='12'>
        <div class='default-table'>
          <el-table :data='dataList' size='mini' max-height='400' highlight-current-row border>
            <el-table-column label='操作时间' align='center' prop='created_at' width='140'></el-table-column>
            <el-table-column label='操作内容' header-align='center' align='left' min-width='200'>
              <template slot-scope='{row}'>
                <span>{{ row.op_name }}</span>
                于
                <span>{{ row.created_at }}</span>

                进行【
                <el-link type='primary' @click='showLogDetail(row)'>{{ row.op_type_alias }}</el-link>
                】操作

              </template>
            </el-table-column>
          </el-table>
          <div style='display: flex'>
            <!--            刷新-->
            <el-button type='text' icon='el-icon-refresh' @click='getList'>刷新</el-button>
            <!--            分页-->
            <pagination :hidden='total===0'
                        :background='true'
                        :total.sync='total'
                        :page.sync='pageData.current_page'
                        :limit.sync='pageData.page_size'
                        @pagination='getList' />
          </div>
        </div>
      </el-col>
      <el-col :lg='14' :xs='24' :sm='24' :md='12'>
        <div v-if='logForm.op_type'>
          <el-divider content-position='left'>{{ logForm.op_title || '' }}</el-divider>
<!--          <div>-->
<!--            <b>日志内容：</b>-->
<!--          </div>-->
          <div v-if="['selection','selection_org','org_selection'].indexOf(logForm.op_type)>-1">
            <el-table :data='logForm.items' height='400' border>
              <el-table-column label='序号' type='index' width='55'></el-table-column>
<!--              <el-table-column label='ID' prop='id'></el-table-column>-->
<!--              <el-table-column label='产品ID' prop='product_id'></el-table-column>-->
              <el-table-column label='产品名称' prop='title'></el-table-column>
<!--              <el-table-column label='直播价' prop='lb_price' width='120' show-overflow-tooltip></el-table-column>-->
<!--              <el-table-column label='赠品' prop='gift' width='120' show-overflow-tooltip></el-table-column>-->
<!--              <el-table-column label='线上佣金' prop='online_cmn'></el-table-column>-->
<!--              <el-table-column label='线下佣金' prop='offline_cmn'></el-table-column>-->
<!--              <el-table-column label='品牌支持' prop='brand_support'></el-table-column>-->
<!--              <el-table-column label='库存/保质期' prop='stock_info'></el-table-column>-->
<!--              <el-table-column label='是否样品' prop='sample_info'></el-table-column>-->
<!--              <el-table-column label='原因' prop='reason'></el-table-column>-->
<!--              <el-table-column label='备注信息' prop='remark'></el-table-column>-->
            </el-table>
          </div>
          <div v-else>
           <p v-html='logForm.log_content'>
           </p>
          </div>
        </div>

      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LogList',
  props: {
    infoId: {
      type: [Number, String]
    },
    opType: {
      type: String,
      default() {
        return null
      }
    }
  },
  watch: {
    infoId: {
      immediate: true,
      handler(val) {
        this.searchCondition.info_id = val
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      loadingStatus: false,
      searchCondition: { op_type: null, info_id: null },
      dataList: [],
      total: 0,
      pageData: { page_size: 10, current_page: 1 },
      logForm: {}
    }
  },
  methods: {
    async getList() {
      this.dataList = []
      if (this.opType) {
        this.searchCondition.op_type = this.opType
      }
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pageData)
      let { list, pages } = await this.$api.getLbScheduleLog(searchCondition)
      this.$nextTick(() => {
        this.dataList = list || []
      })
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 1
      this.total = pages.total
    },
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.info_id) {
        condition.info_id = this.searchCondition.info_id
      }

      if (this.searchCondition.op_type) {
        condition.op_type = this.searchCondition.op_type
      }

      return condition
    },
    async showLogDetail(row) {
      let logForm = await this.$api.getLbScheduleLogDetail(row.id)
      if (logForm)
        this.logForm = logForm
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>

</style>